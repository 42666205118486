import style from './style.module.css';

const GirlBanner = () => (
  <a href="http://localhost:3000/?lazy=true&qaxpv=1759282b91a925ce52&uclick=wfd5oc3z&uclickhash=wfd5oc3z-wfwh6jci-yd-0-6ji4-q5kt-ussc-95e6c9">
    <div class={style.girlBanner}>
      <img
        src="/assets/images/hotBanner.webp"
        style={{ cursor: 'pointer' }}
        alt="user"
        class={style.desktopBannerImage}
      />
      <img
        src="/assets/images/mobileHotBanner.webp"
        style={{ cursor: 'pointer' }}
        alt="user"
        class={style.mobileBannerImage}
      />
    </div>
  </a>
);

export default GirlBanner;
