const COMMON_CONTENT_CONFIG = {
  title: 'Exclusive video of a porn star: Hayley Davies',
  recommended: {
    left: [
      {
        imagePath: '/assets/images/ebony/preview1.webp',
        title: 'Two sexy blonds and a brunette',
        description: 'Engage in lesbian double penetration action with a strapon',
        viewsCount: '287k',
        likesPercent: 98,
      },
      {
        imagePath: '/assets/images/ebony/preview2.webp',
        title: 'Hayley Davies',
        description: 'Hot busty blonde catches panty-sniffer so she gives him a handjob to remember',
        viewsCount: '964k',
        likesPercent: 76,
      },
    ],
    right: [
      {
        imagePath: '/assets/images/ebony/preview3.webp',
        title: 'Jason Luv',
        description: 'Stuffs Cheating bubble butt blondes pussy',
        viewsCount: '1.3M',
        likesPercent: 87,
      },
      {
        imagePath: '/assets/images/ebony/preview4.webp',
        title: 'Dan Dangler, Porn ASMR Reaction',
        description: 'Rough Hard Sex From Maximo Garcia - Amateur Willow Harper!',
        viewsCount: '461k',
        likesPercent: 96,
      },
    ],
    bottom: [
      {
        imagePath: '/assets/images/ebony/preview5.webp',
        title: 'Petite Busty',
        description: 'Fitness Model Oiled Up Fucking',
        viewsCount: '1.3M',
        likesPercent: 97,
      },
      {
        imagePath: '/assets/images/ebony/preview6.webp',
        title: 'Bubble butt blonde',
        description: 'bends over for doggy after getting face fucked',
        viewsCount: '334k',
        likesPercent: 87,
      },
      {
        imagePath: '/assets/images/ebony/preview7.webp',
        title: 'Gorgeous Sisi Rose',
        description: 'Gets FucKed, squirts, rims and SNORTS cum',
        viewsCount: '2.5M',
        likesPercent: 92,
      },
      {
        imagePath: '/assets/images/ebony/preview8.webp',
        title: 'Anna Cherry',
        description: 'My friend fucks me in his room, I like to break my ass',
        viewsCount: '978k',
        likesPercent: 89,
      },
      {
        imagePath: '/assets/images/ebony/preview9.webp',
        title: 'Team Skeet',
        description: 'Hot Carmen Caliente with big booty gets her pussy drilled',
        viewsCount: '1.7M',
        likesPercent: 84,
      },
    ],
  },
  videoPath: '/assets/videos/video.mp4',
};

export const DETAILED_PAGE_CONFIGS = {
  ebony: COMMON_CONTENT_CONFIG,
  latina: COMMON_CONTENT_CONFIG,
  creampie: COMMON_CONTENT_CONFIG,
  anal: COMMON_CONTENT_CONFIG,
  asian: COMMON_CONTENT_CONFIG,
  lesbian: COMMON_CONTENT_CONFIG,
  gay: COMMON_CONTENT_CONFIG,
  milf: COMMON_CONTENT_CONFIG,
  blowjob: COMMON_CONTENT_CONFIG,
};
