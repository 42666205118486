import Helmet from 'preact-helmet';

import { DETAILED_PAGE_CONFIGS } from '../../../shared/constants/detailedPagesConfigs';
import RecommendationCard from '../../RecomendationCard';
import VideoBlock from '../../VideoBlock';
import style from './style.module.css';

const DetailedPage = ({ category }) => {
  return (
    <div>
      <Helmet
        link={[{ rel: 'shortcut icon', href: '/assets/favicon.png' }]}
        title={`${category.toUpperCase()} Page`}
        meta={[{ name: 'description', content: `${category.toUpperCase()} Page` }]}
      />
      <div class={style.root}>
        <h1 class={style.title}>{DETAILED_PAGE_CONFIGS[category].title}</h1>
        <div class={style.mainContentWrapper}>
          <div class={style.leftRecomendationsBlock}>
            {DETAILED_PAGE_CONFIGS[category].recommended.left.map((card, index) => {
              return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
            })}
          </div>
          <VideoBlock imagePath={DETAILED_PAGE_CONFIGS[category].videoPath} />
          <div class={style.rightRecomendationsBlock}>
            {DETAILED_PAGE_CONFIGS[category].recommended.right.map((card, index) => {
              return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
            })}
          </div>
        </div>
        <div class={style.title}>More videos</div>
        <div class={style.bottomRecomendationBlock}>
          {DETAILED_PAGE_CONFIGS[category].recommended.bottom.map((card, index) => {
            return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
          })}
        </div>
        <div class={style.commentsBlock}>
          <p class={style.commentsCount}>Comments (12)</p>
          <p class={style.commentsNotification}>You need to be authorised to read all comments</p>
        </div>
      </div>
    </div>
  );
};

export default DetailedPage;
