import { AdultIcon, LogoIcon } from '../../assets/icons';
import { ABOUT_LINKS, FOOTER_DESCRIPTION, HELP_SUPPORT, LEGAL_SAFETY, SPONSORS } from '../../shared/constants/footer';
import style from './style.module.css';

const Footer = () => (
  <footer class={style.footer}>
    <div class={style.footerContent}>
      <div class={style.description}>
        <div class={style.logo}>
          <a href="/">
            <img src={LogoIcon} width={149} height={50} alt="logo" />
          </a>
          <img src={AdultIcon} width={32} height={32} alt="adultsOnly" />
        </div>
        <div class={style.desktopDescription}>
          <div class={style.descriptionText}>
            {FOOTER_DESCRIPTION.map((descr, index) => {
              return (
                <p key={index} class={style.descriptionParagraph}>
                  {descr}
                </p>
              );
            })}
          </div>
          <div class={style.sponsors}>
            {SPONSORS.map((sponsor, index) => {
              return <img key={index} src={sponsor} alt="sponsor" />;
            })}
          </div>
        </div>
      </div>
      <div class={style.linksBlock}>
        <p class={style.linksTitle}>ABOUT</p>
        {ABOUT_LINKS.map(link => {
          return (
            <div key={link.title} class={style.navLink}>
              <img src={link.icon} alt={link.title} />
              <p>{link.title}</p>
            </div>
          );
        })}
      </div>
      <div class={style.linksBlock}>
        <p class={style.linksTitle}>LEGAL & SAFETY</p>
        {LEGAL_SAFETY.map(link => {
          return (
            <div key={link} class={style.navLink}>
              <p>{link}</p>
            </div>
          );
        })}
      </div>
      <div class={style.linksBlock}>
        <p class={style.linksTitle}>HELP & SUPPORT</p>
        {HELP_SUPPORT.map(link => {
          return (
            <div key={link} class={style.navLink}>
              <p>{link}</p>
            </div>
          );
        })}
      </div>
    </div>
    <div class={style.mobileDescription}>
      <div class={style.descriptionText}>
        {FOOTER_DESCRIPTION.map((descr, index) => {
          return (
            <p key={index} class={style.descriptionParagraph}>
              {descr}
            </p>
          );
        })}
      </div>
      <div class={style.sponsors}>
        {SPONSORS.map((sponsor, index) => {
          return <img key={index} src={sponsor} alt="sponsor" />;
        })}
      </div>
    </div>
    <div class={style.rights}>
      <p>© 2024 Stripchat.com</p>
      <p>Technius Ltd 334 Agiou Andreou, Vashiotis Ag. Andreou Business Center, 2nd floor, 3035, Limassol, Cyprus</p>
    </div>
  </footer>
);

export default Footer;
