import { useState } from 'preact/hooks';
import { Router } from 'preact-router';

// eslint-disable-next-line import/no-unresolved
import './style';
import Footer from './components/Footer';
import Header from './components/Header';
import Loader from './components/Loader';
import Navbar from './components/Navbar';
import DetailedPage from './components/pages/DetailedPage';
import MainPage from './components/pages/MainPage';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  return isLoading ? (
    <Loader setIsLoading={setIsLoading} />
  ) : (
    <div id="app">
      <Header />
      <Navbar />
      <main>
        <Router>
          <MainPage path="/" />
          <DetailedPage path="/:category" />
        </Router>
      </main>
      <Footer />
    </div>
  );
};

export default App;
