import style from './style.module.css';

const Header = () => (
  <header class={style.header}>
    <div class={style.contentWrapper}>
      <a href="/" class={style.logo}>
        <img src="../../assets/icons/logo.svg" alt="Preact Logo" height="70" width="208" />
      </a>
      <div class={style.inputWrapper}>
        <img src="../../assets/icons/search.svg" alt="search" class={style.searchIcon} height="16" width="16" />
        <input class={style.searchInput} placeholder="Search videos" />
        <img
          src="../../assets/icons/searchSettings.svg"
          alt="search"
          class={style.searchSettingsIcon}
          height="16"
          width="16"
        />
      </div>
    </div>
    <img src="../../assets/icons/avatar.svg" style={{ cursor: 'pointer' }} alt="user" height="40" width="40" />
  </header>
);

export default Header;
