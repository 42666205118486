import {
  InfoIcon,
  MediaIcon,
  Sponsor1Icon,
  Sponsor2Icon,
  Sponsor3Icon,
  Sponsor4Icon,
  TwitterIcon,
} from '../../assets/icons';

export const FOOTER_DESCRIPTION = [
  'Stripchat is an 18+ LIVE sex & entertainment community. You can watch streams from amateur & professional models for absolutely free. Browse through thousands of open-minded people: naked girls, guys, transsexuals and couples performing live sex shows.',
  'Besides watching the shows, you can also control performers with interactive toys, select what desire you want to explore from the tokens menu, go for Private shows and use your camera to connect more intimately. The purpose of all of that to create an ultimate destination for adult entertainment filled with good vibes and happy aftertaste.',
  'All models appearing on this site have contractually confirmed to us that they are 18 years of age or older. ',
];

export const SPONSORS = [Sponsor1Icon, Sponsor2Icon, Sponsor3Icon, Sponsor4Icon];

export const ABOUT_LINKS = [
  { icon: InfoIcon, title: 'About us' },
  { icon: TwitterIcon, title: 'Twitter' },
  { icon: MediaIcon, title: 'Media Inquiries' },
];

export const LEGAL_SAFETY = [
  'Privacy Policy',
  'Terms of Use',
  'DMCA Policy',
  'Cookies Policy',
  'Parental Control Guide',
  'EU Research Program',
  'Anti-Slavery Help',
];

export const HELP_SUPPORT = ['Support & FAQ', 'Billing Support', 'DMCA Protection'];
