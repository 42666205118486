import { CameraIcon } from '../../assets/icons';
import style from './style.module.css';

const CategoryCard = ({ imgSrc, videosAmount, title }) => (
  <div class={style.categoryCard}>
    <img src={imgSrc} alt={title} class={style.categoryImage} />
    <div class={style.videosBadge}>
      <img src={CameraIcon} width={20} height={20} alt="videos" />
      <p>{videosAmount} videos</p>
    </div>
    <div class={style.categoryBadge}>{title}</div>
  </div>
);

export default CategoryCard;
