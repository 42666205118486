export const NAVBAR_CONFIG = [
  { title: 'Home', isSelected: false, isDropdown: false },
  { title: 'Porn Videos', isSelected: false, isDropdown: false },
  { title: 'Categories', isSelected: true, isDropdown: true },
  { title: 'Fuck Now', isSelected: false, isDropdown: false },
  { title: 'Community', isSelected: false, isDropdown: true },
  { title: 'Photos & Gifs', isSelected: false, isDropdown: true },
  { title: 'Top Videos', isSelected: false, isDropdown: false },
  { title: 'Pornstars', isSelected: false, isDropdown: true },
];
