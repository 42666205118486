export const CATEGORIES = [
  { imgSrc: '/assets/images/ebony.webp', title: 'Ebony', videosAmount: 2571 },
  { imgSrc: '/assets/images/latina.webp', title: 'Latina', videosAmount: 1526 },
  { imgSrc: '/assets/images/asian.webp', title: 'Asian', videosAmount: 1170 },
  { imgSrc: '/assets/images/creampie.webp', title: 'Creampie', videosAmount: 2203 },
  { imgSrc: '/assets/images/anal.webp', title: 'Anal', videosAmount: 1200 },
  { imgSrc: '/assets/images/lesbian.webp', title: 'Lesbian', videosAmount: 1078 },
  { imgSrc: '/assets/images/gay.webp', title: 'Gay', videosAmount: 723 },
  { imgSrc: '/assets/images/milf.webp', title: 'MILF', videosAmount: 2351 },
  { imgSrc: '/assets/images/blowjob.webp', title: 'Blowjob', videosAmount: 922 },
];
