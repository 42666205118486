import { LikeIcon, StarIcon, ViewsIcon } from '../../assets/icons';
import style from './style.module.css';

const RecommendationCard = ({ cardConfig }) => {
  const { title, description, imagePath, viewsCount, likesPercent } = cardConfig;

  return (
    <div class={style.root}>
      <img src={imagePath} alt={title} class={style.image} />
      <div class={style.descriptionBlock}>
        <div class={style.titleBlock}>
          <img src={StarIcon} width={16} height={16} alt="star" />
          <p class={style.title}>{title}</p>
        </div>
        <p class={style.description}>{description}</p>
        <div class={style.viewsBlock}>
          <div class={style.viewsValue}>
            <img src={ViewsIcon} width={16} height={16} alt="views" />
            <p>{viewsCount}</p>
          </div>
          <div class={style.viewsValue}>
            <img src={LikeIcon} width={16} height={16} alt="like" />
            <p>{likesPercent}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationCard;
