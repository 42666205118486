import clsx from 'clsx';

import { LoaderLogoIcon } from '../../assets/icons';
import style from './style.module.css';

const Loader = ({ setIsLoading }) => {
  return (
    <div class={style.root}>
      <img src={LoaderLogoIcon} alt="logo" class={style.logo} />
      <div class={clsx(style.loader, style['loader-14'])}>
        <svg
          class={clsx(style['loader-star'], style['star-small'])}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 23.172 23.346"
        >
          <polygon
            fill="#FFBF00"
            points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9  "
          />
        </svg>
        <svg
          class={clsx(style['loader-star'], style['star-big'])}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 23.172 23.346"
        >
          <polygon
            fill="#FFDF80"
            points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9  "
          />
        </svg>
        <svg
          class={clsx(style['loader-star'], style['fullscreen-star-big'])}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 23.172 23.346"
          onAnimationEnd={() => {
            setIsLoading(false);
          }}
        >
          <polygon
            fill="#FFBF00"
            points="11.586,0 8.864,8.9 0,8.9 7.193,14.447 4.471,23.346 11.586,17.84 18.739,23.346 16.77,14.985 23.172,8.9 14.306,8.9  "
          />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
