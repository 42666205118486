import Helmet from 'preact-helmet';

import { CATEGORIES } from '../../../shared/constants/categories';
import CategoryCard from '../../CategoryCard';
import GirlBanner from '../../GirlBanner';
import style from './style.module.css';

const MainPage = () => {
  return (
    <div>
      <Helmet
        link={[{ rel: 'shortcut icon', href: '/assets/favicon.png' }]}
        title="Main Page"
        meta={[{ name: 'description', content: 'Main page' }]}
      />
      <div class={style.home}>
        <div class={style.categoriesSwitcherBlock}>
          <p style={{ fontSize: '1.25rem', fontWeight: 700 }}>All Porn Categories</p>
          <div class={style.categoriesSwitcher}>
            <p class={style.selectedTab}>All</p>
            <p>Gay</p>
          </div>
        </div>
        <div class={style.categoriesWrapper}>
          {CATEGORIES.map(category => {
            return (
              <a key={category.title} href={`/${category.title.toLowerCase()}`}>
                <CategoryCard
                  key={category.title}
                  imgSrc={category.imgSrc}
                  videosAmount={category.videosAmount}
                  title={category.title}
                />
              </a>
            );
          })}
        </div>
        <GirlBanner />
      </div>
    </div>
  );
};

export default MainPage;
