import clsx from 'clsx';

import { ArrowDownIcon } from '../../assets/icons';
import { NAVBAR_CONFIG } from '../../shared/constants/navbar';
import style from './style.module.css';

const Navbar = () => (
  <nav class={style.navbar}>
    {NAVBAR_CONFIG.map(navItem => {
      return (
        <a href="/" class={clsx(style.navItem, navItem.isSelected && style.selected)}>
          <p style={{ fontSize: '1rem', fontWeight: 700 }}>{navItem.title}</p>
          {navItem.isDropdown && (
            <img
              src={ArrowDownIcon}
              class={clsx(!navItem.isSelected && style.notSelectedArrow)}
              alt="user"
              height="5"
              width="10"
            />
          )}
        </a>
      );
    })}
  </nav>
);

export default Navbar;
